import React from 'react'



class ServicesTitle extends React.Component {
 

  render () {
    return (
        <React.Fragment>

      <div className="titleBackground">
<div id="servicesTitle">OUR SERVICES</div>
</div>
      </React.Fragment>
    )
  }
}

export default ServicesTitle